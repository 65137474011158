import React from 'react';
import './Projects.css'; // Import CSS file
import { GithubOutlined, YoutubeOutlined, FilePdfOutlined } from '@ant-design/icons';
import NHLPDF from "./nhlPDF.pdf"
import CNNPDF from "./cnnPDF.pdf"
interface ProjectPageProps {
  // Define any props needed for your project page component
}

const Projects: React.FC<ProjectPageProps> = () => {
  const githubNotafy = () => {
    window.open('https://github.com/nickpiccaro/notafy-api', '_blank');
  };

  const githubBookWise = () => {
    window.open('https://github.com/nickpiccaro/BookWise', '_blank');
  };

  const youtubeBookWise = () => {
    window.open('https://www.youtube.com/watch?v=RZsfAV4Pql0', '_blank');
  };

  const githubNHL = () => {
    window.open('https://github.com/nickpiccaro/DSCI478-Final', '_blank');
  };

  const openNHLPdf = () => {
    window.open(NHLPDF, '_blank');
  };

  const githubCNN= () => {
    window.open('https://github.com/nickpiccaro/CS445-Final_Project', '_blank');
  };

  const openCNNPdf = () => {
    window.open(CNNPDF, '_blank');
  };


  return (
    <div className="projectBody">
      <section className="project">
        <div className="projectTitle"> 
          <h2>Projects</h2>
        </div>
        <div className="projectPages"> 
          <div className = "projectPage one">
            <div className="projectHeader">
              <div className="projectName">Notafy | SMS Reminder API</div>
              <div className="techStack">Typescript | Node.js | Express.js | MongoDB </div>
              <div className="projectButtons">
                <GithubOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={githubNotafy}/>
              </div>
            </div>
            <div className="projectDetail">
              Notafy is a SMS Reminder service, enabling users to create and receive reminders seamlessly through a <strong style={{ color: '#06d6a0' }}>text message interface.</strong> 
              Many are far more likely to read a text message than a reminder, Notafy is a functional solution to ensure that no appointment or due date is missed.
              Utilizing the <strong style={{ color: '#06d6a0' }}>Twilio API </strong>functionality, this API is entirely managed on the web. 
            </div>
          </div>
          <div className = "projectPage two">
            <div className="projectHeader">
              <div className="projectName">BookWise </div>
              <div className="techStack"> TypeScript | Python | React.js | Flask | PyTorch</div>
              <div className="projectButtons">
                <GithubOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={githubBookWise}/>
                <YoutubeOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={youtubeBookWise}/>
              </div>
            </div>
            <div className="projectDetail">
            BookWise is an innovative application that harnesses the power of <strong style={{ color: '#06d6a0' }}>object detection</strong> and <strong style={{ color: '#06d6a0' }}>optical character recognition (OCR)</strong> technologies to find books using just a single image. 
            By leveraging state-of-the-art machine learning algorithms, BookWise revolutionizes the way you discover books.
            </div>
          </div>
          <div className = "projectPage three">
            <div className="projectHeader">
              <div className="projectName">NHL | Better Bets</div>
              <div className="techStack"> Python | Jupyter Notebooks | TensorFlow | Keras </div>
              <div className="projectButtons">
                <GithubOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={githubNHL}/>
                <FilePdfOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={openNHLPdf}/>
              </div>
            </div>
            <div className="projectDetail">
              In this project several statistical modeling techniques were used to try to model National Hockey League game results with an intention of being succeessful in sports betting. It's reported that to be profitable in the long term in sports betting that an individual needs <strong style={{ color: '#06d6a0' }}> 53%  </strong>accuracy.
              With our models, especially our Neural Network, we reported accuracy rates of <strong style={{ color: '#06d6a0' }}>69.4%</strong> which would be highly profitable.
              Our team all tested our models, and on  <strong style={{ color: '#06d6a0' }}>9 out of 10</strong> games we made a profit.
            </div>
          </div>
          <div className = "projectPage four">
            <div className="projectHeader">
              <div className="projectName">CNN Brain Tumor Detection</div>
              <div className="techStack"> Python | Jupyter Notebooks | TensorFlow </div>
              <div className="projectButtons">
                <GithubOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={githubCNN}/>
                <FilePdfOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={openCNNPdf}/>
              </div>
            </div>
            <div className="projectDetail">
              In this project we utilized Convolutional Neural Networks to detect and predict whether a brain MRI image had a brain tumor or not.
              We utilized an architecture of 3 Convolutional layers, as well a effective distribution of Batch Normalization, Max Pooling, Dense, and Dropout Layers.
              On our cross validation testing dataset our CNN resulted in an accuracy rate of <strong style={{ color: '#06d6a0' }}>~99%</strong>, proving to be very effective.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Projects;