import React from 'react';
import './About.css'; // Import CSS file
import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PDF from "./resume.pdf"

interface AboutPageProps {
  // Define any props needed for your About page component
}

const About: React.FC<AboutPageProps> = () => {

    const openResumePdf = () => {
        window.open(PDF, '_blank');
    };
  return (
    <div className="aboutBody">
      <section className="about">
        <div className="aboutContent">
          <div className="section">
            <div className="sectionHeader">Background</div>
            <div className="sectionContent">
              <p>I'm currently an <span style={{ color: '#06d6a0' }}>Software Engineer</span> at Deloitte working with applied AI and gaining experience in software development and machine learning. I recently graduated from Colorado State University after completing my degree in Data Science and Computer Science.</p>

              <p>As a software engineer my areas of interest include <span style={{ color: '#06d6a0' }}>Quantitative Trading, Computer Vision, NLP, and FinTech.</span>In my personal time, I am working on personal projects to bolster my development skills and practicing applying machine learning into real applications.</p>

              <p>When I am not working you can find me doing a whole array of different activites or pursuing various interests, see the bottom of this page for all that I do and enjoy.</p>
            </div>
          </div>
          <div className="section">
            <div className="sectionHeader">Work Experience</div>
            <div className="sectionContent">
              <p>
                <a href="https://www.youtube.com/watch?v=fgXTqxe_LgM" target="_blank" rel="noopener noreferrer" style={{ color: '#06d6a0' }}>
                    <strong> Software Engineer </strong> <LinkOutlined /> &nbsp;
                </a> 
                <br />
                Deloitte | Boston, MA | July 2022-Present
                <br />
                Collaborating with a cross-functional team to develop new features on Deloitte's advanced conversational AI workbench flow builder known as Trueserve DNA. Utilizing AWS, Docker, Vue.js, Node.js, Express.js, and PostgreSQL.
              </p>
              <p>
                <a href="https://statlab.colostate.edu/" target="_blank" rel="noopener noreferrer" style={{ color: '#06d6a0' }}>
                    <strong> Statistical Consulting Intern </strong> <LinkOutlined /> &nbsp;
                </a>
                <br />
                CSU Graybill Statistics and Data Science Laboratory | Fort Collins, CO | Sep. 2021-May 2022
                <br />
                Assisted and provided valuable insights on statistical consultations with professionals/researchers. Collaborated on experiment design and data analysis, enhancing project outcomes.
              </p>
            </div>
          </div>
          <div className="section">
            <div className="sectionHeader">Education</div>
            <div className="sectionContent">
              <p>
                <strong>BS in <strong style={{ color: '#06d6a0' }}>Data Science</strong>, with <strong style={{ color: '#06d6a0' }}>Computer Science</strong> Concentration</strong>
                <br />
                Colorado State University | Fort Collins, CO | May 2022 | <strong style={{ color: '#06d6a0' }}>GPA: 3.8</strong>
                
                
              </p>
            </div>
          </div>
          <div className="section">
            <div className="sectionHeader">Certifications</div>
            <div className="sectionContent">
              <p >Deloitte Full Stack AI Engineer Certification (In Progress)</p>
            </div>
          </div>
          <div className="section">
            <div className="sectionHeader">Technical Skills</div>
            <div className="sectionContent">
              <div className="columns">
                <div className="column">
                  <h4 style={{ color: '#06d6a0' }}>Languages</h4>
                  <ul>
                    <li>Python</li>
                    <li>Javascript</li>
                    <li>Typescript</li>
                    <li>C++</li>
                    <li>SQL</li>
                    <li>HTML/CSS</li>
                    <li>R</li>
                    <li>Java</li>
                  </ul>
                </div>
                <div className="column">
                  <h4 style={{ color: '#06d6a0' }}>Frameworks</h4>
                  <ul>
                    <li>React.js</li>
                    <li>Next.js</li>
                    <li>Vue.js</li>
                    <li>Node.js</li>
                    <li>Express.js</li>
                    <li>MongoDB</li>
                    <li>PostgreSQL</li>
                    <li>Flask</li>
                  </ul>
                </div>
                <div className="column">
                  <h4 style={{ color: '#06d6a0' }}>Developer Tools</h4>
                  <ul>
                    <li>Git</li>
                    <li>AWS</li>
                    <li>Docker</li>
                    <li>Jupyter Notebooks</li>
                    <li>VSCode</li>
                    <li>Postman</li>
                    <li>Jira</li>
                    <li>Confluence</li>
                  </ul>
                </div>
                <div className="column">
                  <h4 style={{ color: '#06d6a0' }}>ML Libraries</h4>
                  <ul>
                    <li>TensorFlow</li>
                    <li>PyTorch</li>
                    <li>Keras</li>
                    <li>Scikit-learn</li>
                    <li>XGBoost</li>
                    <li>LightGBM</li>
                    <li>NLTK</li>
                    <li>Pandas</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="sectionHeader">Interests</div>
            <div className="sectionContent">
            <div className="columns">
                <div className="column">
                  <ul>
                    <li>Formula1</li>
                    <li>Sim Racing</li>
                    <li>Hockey</li>
                    <li>Rugby</li>
                    <li>Swimming</li>
                    <li>Olympic Lifting</li>
                  </ul>
                </div>
                <div className="column">
                  <ul>
                    <li>Travel</li>
                    <li>Language Learning</li>
                    <li>Classical Piano</li>
                    <li>Culinary Arts</li>
                    <li style={{ color: '#06d6a0' }}>Learning about any and everything!!!</li>
                  </ul>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div className="buttonSection">
            <Button className="resumeButton" type="primary" onClick={openResumePdf} size="large">Resume </Button>
        </div>
      </section>
    </div>
  );
};

export default About;
