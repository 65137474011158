import React from 'react';
import Parallax from '../../components/parallax/Parallax';
import '../../views/Home/Home.css'; // Import CSS file

interface HomePageProps {
  // Define any props needed for your home page component
}

const Home: React.FC<HomePageProps> = () => {
  // Sample function to be called on button press
  // const handleButtonClick = () => {
  //   // Add your logic here
  //   console.log("DEBUG | buttonClick");
  // };

  return (
    <div className="homeBody">
      <div className="intro">
        <div className="introLead">Hello, I am...</div>
        <div className="introName">Nick Piccaro</div>
        <div className="introInfo">Software Engineer | ML Engineer </div>
      </div>

      <div className="parallax-container">
        <Parallax />
      </div>

    </div>
  );
};

export default Home;