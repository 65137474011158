import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { GithubOutlined, LinkedinOutlined, MailOutlined } from '@ant-design/icons';
import Home from './views/Home/Home';
import About from './views/About/About';
import Projects from './views/Projects/Projects';
import './App.css'; // Import CSS file

const App: React.FC = () => {
  const openGithub = () => {
    window.open('https://github.com/nickpiccaro', '_blank');
  };
  const openLinkedin = () => {
    window.open('https://www.linkedin.com/in/nicholas-piccaro-03a128182', '_blank');
  };
  const openEmail = () => {
    window.open('mailto:npiccaro10@gmail.com', '_blank');
  };

  return (
    <div>
      <div className="socialBar">
        <div className="vline"></div>
        <GithubOutlined style={{ color: 'white', fontSize: '35px', margin: '6px' }} onClick={openGithub}/>
        <div className="spacing"/> 
        <LinkedinOutlined style={{ color: 'white', fontSize: '35px', margin: '6px'}} onClick={openLinkedin}/>
        <div className="spacing"/> 
        <MailOutlined style={{ color: 'white', fontSize: '32px', margin: '6px'}} onClick={openEmail} />
        <div className="vline"></div>
      </div>
      <Router>
        <div>
          <nav className="navBar">
            <div className="vlineMenu"></div>
          
            <NavLink 
              to="/"
              className={({ isActive, isPending }) =>
                isPending ? "pendingLink" : isActive ? "activeLink" : "pendingLink"
              }
            >
                Home
            </NavLink>
            
            <div className="spacing"/> 
            <div className="spacing"/> 
            <div className="spacing"/>
            <NavLink
              to="/about"
              className={({ isActive, isPending }) =>
                isPending ? "pendingLink" : isActive ? "activeLink" : "pendingLink"
              }
            >
              About
            </NavLink>
            <div className="spacing"/> 
            <div className="spacing"/> 
            <div className="spacing"/>
            <NavLink
              to="/projects"
              className={({ isActive, isPending }) =>
                isPending ? "pendingLink" : isActive ? "activeLink" : "pendingLink"
              }
            >
              Projects
            </NavLink>
            <div className="vlineMenu"></div>
          </nav>
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/about" element={<About/>}></Route>
            <Route path="/projects" element={<Projects/>}></Route>
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
